<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personInfo">晨午检</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      :rules="ruleValidate"
      inline
      class="demo-form-inline"
    >
      <div>
        <el-form-item label="班级" prop="companyId">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
          ></el-select-tree
          >&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >
        </el-form-item>
        <el-form-item label="编号" prop="personId">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.personId"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="personName">
          <el-input
            type="text"
            size="mini"
            v-model="queryModel.personName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="handleQuery('queryForm')"
            :loading="loading"
            >查询</el-button
          >&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
          >&nbsp;
        </el-form-item>
      </div>
      <div>
        <el-form-item label="检查日期" prop="findTime">
          <el-date-picker
            v-model="queryModel.findTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="晨检时间" prop="morningTimeRanges">
          <el-date-picker
            v-model="queryModel.morningTimeRanges"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="morningTimeRangesDefaultTime"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="午检时间" prop="noonTimeRanges">
          <el-date-picker
            v-model="queryModel.noonTimeRanges"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="noonTimeRangesDefaultTime"
            size="mini"
          ></el-date-picker>
        </el-form-item> -->
        <el-form-item label="检测情况" prop="detectResult">
          <el-select
            v-model="queryModel.detectResult"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="全部" value></el-option>
            <el-option label="已检测" value="2"></el-option>
            <el-option label="未检测" value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="体温结果" prop="temperatureResult">
          <el-select
            v-model="queryModel.temperatureResult"
            size="mini"
            filterable
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="全部" value></el-option>
            <el-option label="异常" value="1"></el-option>
            <el-option label="正常" value="0"></el-option>
          </el-select>
        </el-form-item> -->
      </div>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <!-- <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="showBatchChangeTimes"
        >设置晨午检时间</el-button
      > -->
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="downloadAll"
        >导出数据</el-button
      > 
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="编号" width="100"></el-table-column>
      <el-table-column prop="name" label="姓名" width="120"></el-table-column>
      <el-table-column
        prop="companyName"
        label="班级"
        width="180"
      ></el-table-column>
      <!-- <el-table-column prop="morningImgUrl" label="" width="100" align="right">
        <template slot-scope="{ row }">
          <a :href="row.morningImgUrl" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.morningImgUrl +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="morningTemperature"
        label="晨检"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="morningTime"
        label=""
        width="180"
        align="left"
      ></el-table-column> -->

      <template v-for="col in inspectionColumns">
         <el-table-column :prop="col.name" :key="col.name" width="80px">
          <template slot-scope="{ row }">
            <div style="width:300px;text-align: left;">
              <template
                v-for="(item, index) in row.inspectionMap[col.name]"
              >
              <a :href="item.faceImgUrl" target="_blank" :key="index" v-if="item.faceImgUrl != null">
                <el-avatar
                  :size="48"
                  shape="circle"
                  :src="
                    item.faceImgUrl +
                    '?x-oss-process=image/resize,m_fill,w_64,h_64'
                  "
                  :key="row.id"
                ></el-avatar>
              </a>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="col.label" :prop="col.name" :key="col.name" width="300px">
          <template slot-scope="{ row }">
            <div style="width:300px;text-align: left;">
              <template
                v-for="(item, index) in row.inspectionMap[col.name]"
              >
                <span :key="index" style="color:green;margin:5px;">{{item.temperature}}</span>
                <span :key="index" style="margin:5px;">{{item.temperatureTime}}</span>
              </template>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination> -->
    <el-dialog
      title="晨午检时间段"
      :visible.sync="batchChangeTimes"
      :modal-append-to-body="false"
      style="text-align: left"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="150px"
        v-loading="boundDisabled"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form-item label="晨检">
          <el-time-picker
            is-range
            v-model="morningTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="午检">
          <el-time-picker
            is-range
            v-model="noonTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeBatchChangeTimes">关 闭</el-button>
        <el-button type="primary" @click="handleBatchChangeTimes"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";

import personInfoApi from "@/api/base/personInfo";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import userApi from "@/api/sys/user";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import { getToken } from "@/utils/auth"; // get token from cookie
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseInspectionList",
  data() {
    var self = this;

    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        // morningTimeRanges: [
        //   { required: true, message: "请选择时间范围", trigger: "blur" },
        // ],
        // noonTimeRanges: [
        //   { required: true, message: "请选择时间范围", trigger: "blur" },
        // ],
        findTime: [{ required: true, message: "请选择日期", trigger: "blur" }],
      },
      queryModel: {
        personId: "",
        personName: "",
        companyId: "",
        subordinate: false,
        morningTimeRanges: "",
        noonTimeRanges: "",
        detectResult: "",
        temperatureResult: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 30, 100, 500],
      multipleSelection: [],
      healthyCodeList: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      morningTimeRangesDefaultTime: [],
      noonTimeRangesDefaultTime: [],
      xlsLoading: false,
      batchChangeTimes: false,
      boundDisabled: false,
      companyModel: {
        id: "",
        morningTimeRanges: "",
        noonTimeRanges: "",
      },
      morningTime: [],
      noonTime: [],
      user: {},
      inspectionColumns: [],
    };
  },
  created() {
    var self = this;
    //this.getCurrentMonthFirst();

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    userApi.userInfo().then((resp) => {
      if (resp.data.result) {
        this.user = resp.data.data;
      }
    });
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      // formData.append("pageIndex", self.pageIndex);
      // formData.append("pageSize", self.pageSize);

      formData.append("personId", self.queryModel.personId);
      formData.append("personName", self.queryModel.personName);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("findTime", self.queryModel.findTime);
      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("detectResult", self.queryModel.detectResult);
      formData.append("temperatureResult", self.queryModel.temperatureResult);

      // if (self.queryModel.morningTimeRanges == null) {
      //   self.queryModel.morningTimeRanges = "";
      // }

      // formData.append("morningTimeRanges", self.queryModel.morningTimeRanges);

      // if (self.queryModel.noonTimeRanges == null) {
      //   self.queryModel.noonTimeRanges = "";
      // }

      // formData.append("noonTimeRanges", self.queryModel.noonTimeRanges);

      personInfoApi
        .inspectionList(formData)
        .then(function (response) {
          self.loading = false;
          var jsonData = response.data;
          if (jsonData.result) {
            self.tableData = jsonData.data.data;

            self.inspectionColumns = jsonData.data.inspectionColumns;

            // self.totalPages = jsonData.totalPages;
            // self.totalElements = jsonData.recordsTotal;
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleQuery() {
      var self = this;
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          self.changePage();
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.queryModel.timeRanges = "";
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    //初始化日期
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      var startDate = new Date();
      var month = parseInt(date.getMonth() + 1);

      //当前测温记录

      var startMonth = month;

      var startTime =
        date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        startDate.getDate() +
        " 00:00:00";

      var endTime =
        date.getFullYear() + "-" + month + "-" + date.getDate() + " 23:59:59";

      self.queryModel.morningTimeRanges = [startTime, endTime];
      self.queryModel.noonTimeRanges = [startTime, endTime];
    },
    downloadAll() {
      //导出
      var self = this;
      var formData = new FormData();

      formData.append("personId", self.queryModel.personId);
      formData.append("personName", self.queryModel.personName);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("findTime", self.queryModel.findTime);
      formData.append("subordinate", self.queryModel.subordinate);

      formData.append("detectResult", self.queryModel.detectResult);
      formData.append("temperatureResult", self.queryModel.temperatureResult);

      // if (self.queryModel.morningTimeRanges == null) {
      //   self.queryModel.morningTimeRanges = "";
      // }

      // formData.append("morningTimeRanges", self.queryModel.morningTimeRanges);

      // if (self.queryModel.noonTimeRanges == null) {
      //   self.queryModel.noonTimeRanges = "";
      // }

      // formData.append("noonTimeRanges", self.queryModel.noonTimeRanges);
      self.xlsLoading = true;
      self.loading = true;

      personInfoApi.exportInspection(formData).then(function (response) {
        self.xlsLoading = false;
        self.loading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null) {
            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        } else {
          self.$message({
            message: jsonData.message + "",
            type: "warning",
          });
        }
      });
    },
    showBatchChangeTimes() {
      var self = this;
      var companyId = self.user.companyId;

      companyInfoApi.edit(companyId).then(function (response) {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.batchChangeTimes = true;
          self.companyModel.id = self.user.companyId;
          var morning = jsonData.data.morningTimeRanges;
          var noon = jsonData.data.noonTimeRanges;

          if (morning != null && morning != "") {
            self.morningTime = morning.split(",");
          }
          if (noon != null && noon != "") {
            self.noonTime = noon.split(",");
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      });
    },
    closeBatchChangeTimes() {
      var self = this;
      self.batchChangeTimes = false;
      self.morningTime = "";
      self.noonTime = "";
    },
    handleBatchChangeTimes() {
      var self = this;
      self.companyModel.morningTimeRanges = self.morningTime + "";
      self.companyModel.noonTimeRanges = self.noonTime + "";

      companyInfoApi.update(self.companyModel).then(function (response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.$message({
            message: "保存成功!",
            type: "success",
          });
          self.batchChangeTimes = false;
        } else {
          self.$message({
            message: jsonData.message + "",
            type: "warning",
          });
        }
      });
    },
    showItem(item) {
      var content = [];

      content.push(item.faceImgUrl);
      content.push(item.temperature);
      content.push(item.temperatureTime);

      return content.join("") ;
    },
  },
  async mounted() {
    var self = this;

    //this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>